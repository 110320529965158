import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

//Packages
import styled from "styled-components";
import { supportsConfig } from "../../../../../AxiosConfig";
import { Context } from "../../../../contexts/Store";
import SectionLoader from "../../../general/helpers/SectionLoader";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    convertToIST,
    getYYYYMMDDIST,
    getYYMMDDHHMM,
    humanise,
} from "../../../general/helpers/function";
import SimpleLoader from "../../../general/helpers/SimpleLoader";

function ProjectDetails({ 
    id,
    isBox,
    setTender,
    isDelete,
    handleDeleteModal,
    setDelete,
    setAssets,
 }) {
    const [projectData, setProjectData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [projectLoading, setProjectLoading] = useState(false);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState();
    const [refresh, setRefresh] = useState(false);
    const [isActive, setActive] = useState("");
    const [isEdit, setEdit] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [projectStatusOverlay, setProjectStatusOverlay] = useState(false);
    const [statusOverlay, setStatusOverlay] = useState(false);
    const [startDate, setStartDate] = useState();
    const [projectCategories, setProjectCategories] = useState();
    const [submit, setSubmit] = useState(false);
    const [error, setError] = useState("");
    const {
        state: { user_data },
    } = useContext(Context);
    const navigate = useNavigate();
    const access_token = user_data.access_token;
    const overlayRef = useRef(null);
    const projectStatusOverlayRef = useRef(null);
    const statusOverlayRef = useRef(null);
    const datePickerRef = useRef(null);
    const date = new window.Date();

    const outsideClick = (e) => {
        if (overlayRef.current && !overlayRef.current.contains(e.target)) {
            setOverlay(false);
        }
    };
    const outsideStatusClick = (e) => {
        if (
            statusOverlayRef.current &&
            !statusOverlayRef.current.contains(e.target)
        ) {
            setStatusOverlay(false);
        }
    };
    const outsideProjectStatusClick = (e) => {
        if (
            projectStatusOverlayRef.current &&
            !projectStatusOverlayRef.current.contains(e.target)
        ) {
            setProjectStatusOverlay(false);
        }
    };
    const handleChange = (e) => {
        e.preventDefault();
        setError("");
        setProjectData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setOverlay(false);
    };

    const handleFieldClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };

    const handleProjectCategories = useCallback((searchTerm) => {
        supportsConfig
            .get("api/v1/tenders/admin/project-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: searchTerm ? { q: searchTerm } : {},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setProjectCategories(data.data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjectCategories();
    }, [handleProjectCategories]);

    const handleProjectsData = () => {
        setProjectLoading(true);
        supportsConfig
            .get(`api/v1/tenders/admin/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    filter_status: isActive,
                    q: searchInput,
                    page: currentPage,
                    pagination: "pagination",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                console.log(data, "data from project single");
                if (StatusCode === 6000) {
                    setPagination(data.pagination_data);
                    setProjectData(data.data);
                    setStartDate(
                        convertToIST(data.data.last_date_of_submission)
                    );
                    setProjectLoading(false);
                } else {
                    if (StatusCode === 6001) {
                        setProjectLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleProjectEditSubmit = () => {
        setProjectLoading(true);
        supportsConfig
            .patch(
                `api/v1/tenders/admin/projects/${id}/`,
                {
                    name: projectData?.name,
                    description: projectData?.description,
                    last_date_of_submission: getYYMMDDHHMM(startDate),
                    project_category: projectData?.project_category,
                    project_status: projectData?.project_status,
                    status: projectData?.status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    }
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                console.log(data, "data from project single");
                if (StatusCode === 6000) {
                    setPagination(data.pagination_data);
                    setProjectData(data.data);
                    setStartDate(
                        convertToIST(data.data.last_date_of_submission)
                    );
                    setProjectLoading(false);
                    setEdit(false);
                } else {
                    if (StatusCode === 6001) {
                        setProjectLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjectsData();
    }, [isActive, searchInput, currentPage, refresh, access_token]);

    useEffect(() => {
        document.addEventListener("click", outsideClick, true);
        document.addEventListener("click", outsideStatusClick, true);
        document.addEventListener("click", outsideProjectStatusClick, true);
        return () => {
            document.removeEventListener("click", outsideClick, true);
            document.removeEventListener("click", outsideStatusClick, true);
            document.removeEventListener(
                "click",
                outsideProjectStatusClick,
                true
            );
        };
    }, []);

    return (
        <MainContainer>
            {projectLoading ? (
                <SimpleLoader />
            ) : (
                <ProjectDetailContainer>
                    <FieldContainer className="heading">
                        <Heading>Details</Heading>
                        <ButtonContainer>
                            <AddNew
                                onClick={() => {
                                    if (isEdit) {
                                        handleProjectEditSubmit();
                                    } else {
                                        setEdit(!isEdit);
                                    }
                                }}
                            >
                                {isEdit ? <span>Save</span> : <span>Edit</span>}
                            </AddNew>
                            <AddNew
                                type="delete"
                                onClick={() => {
                                    // handleProjectDelete();
                                    setTender(true);
                                    setDelete(true);
                                    setAssets(false);
                                }}
                            >
                                <span>Delete</span>
                            </AddNew>
                        </ButtonContainer>
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Name</Label>
                        :
                        {
                            isEdit ? 
                            <InputValue
                                type="text"
                                value={`${projectData.name}`}
                                name="name"
                                onChange={(e) => handleChange(e)}
                                disabled={!isEdit}
                                className={isEdit && "active"}
                            />
                            :
                            <TextField>
                                {projectData.name}
                            </TextField>

                        }
                    </FieldContainer>
                    <FieldOuterContainer>
                        <FieldContainer>
                            <Label>Tender ID</Label>
                            :
                            {
                                isEdit ? 
                                <InputValue
                                    type="text"
                                    value={`${projectData.tender_id}`}
                                    disabled
                                />
                                :
                                <TextField>
                                    {projectData.tender_id}
                                </TextField>

                            }
                        </FieldContainer>
                        <FieldContainer>
                            <Label>Last Date of Submission</Label>
                            :
                            {
                                isEdit ? 
                                <InputValue
                                    type="text"
                                    value={startDate}
                                    disabled={!isEdit}
                                    className={isEdit && "active"}
                                    onClick={() => handleFieldClick()}
                                />
                                :
                                <TextField>
                                    {getYYYYMMDDIST(startDate)}
                                </TextField>

                            }
                            <PickerDiv className={isEdit && "active"}>
                                <DatePicker
                                    ref={datePickerRef}
                                    value=""
                                    selected={startDate}
                                    maxDate={date}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    onChange={(date) => {
                                        console.log(
                                            date,
                                            "date from date picker"
                                        );
                                        setStartDate(date);
                                    }}
                                />
                            </PickerDiv>
                        </FieldContainer>
                    </FieldOuterContainer>

                    <FieldContainer>
                        <Label>Project Category</Label>
                        :
                        
                        {
                            isEdit ? 
                            <InputValue
                                value={projectData?.project_category_name}
                                name="project_category_name"
                                placeholder="Project category"
                                onClick={() => setOverlay(!overlay)}
                                disabled={!isEdit}
                                className={isEdit && "active"}
                                onChange={(e) => {
                                    handleProjectCategories(e.target.value);
                                    handleChange(e);
                                    setOverlay(true);
                                }}
                            />
                            :
                            <TextField>
                                {projectData.project_category_name}
                            </TextField>

                        }
                        {overlay && (
                            <SelectArea ref={overlayRef}>
                                {projectCategories.length > 0 ? (
                                    projectCategories?.map((category) => (
                                        <li
                                            value={category.id}
                                            name="project_category"
                                            onClick={() => {
                                                setProjectData((prev) => ({
                                                    ...prev,
                                                    project_category:
                                                        category.id,
                                                    project_category_name:
                                                        category.title,
                                                }));
                                                setOverlay(false);
                                            }}
                                        >
                                            {category.title}
                                        </li>
                                    ))
                                ) : (
                                    <li>No Data</li>
                                )}
                            </SelectArea>
                        )}
                        {submit && projectData.project_category && error ? (
                            <Error>{error}</Error>
                        ) : (
                            submit &&
                            !projectData.project_category && (
                                <Error>This field is required</Error>
                            )
                        )}
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Description</Label>:
                        {!isEdit ? (
                            <TextField>{projectData.description}</TextField>
                        ) : (
                            <TextArea
                                type="text"
                                value={`${projectData.description}`}
                                disabled={!isEdit}
                                name="description"
                                onChange={(e) => handleChange(e)}
                                className={isEdit && "active"}
                            />
                        )}
                    </FieldContainer>
                    {/* <FieldContainer>
                        <Label>Project Status</Label>
                        :
                        {!isEdit ? (
                            <TextField>{projectData.project_status == "10"
                                ? "Active"
                                : "Closed"}</TextField>
                        ) : (
                            <InputValue
                                type="text"
                                value={
                                    projectData.project_status == "10"
                                        ? "Active"
                                        : "Closed"
                                }
                                disabled={!isEdit}
                                className={isEdit && "active"}
                                onClick={() =>
                                    setProjectStatusOverlay(!projectStatusOverlay)
                                }
                            />
                        )}
                        {projectStatusOverlay && (
                            <SelectArea ref={projectStatusOverlayRef}>
                                <li
                                    value="10"
                                    name="status"
                                    onClick={() => {
                                        setProjectData((prev) => ({
                                            ...prev,
                                            project_status: "10",
                                        }));
                                        setProjectStatusOverlay(false);
                                    }}
                                >
                                    Active
                                </li>
                                <li
                                    value="20"
                                    name="status"
                                    onClick={() => {
                                        setProjectData((prev) => ({
                                            ...prev,
                                            project_status: "20",
                                        }));
                                        setProjectStatusOverlay(false);
                                    }}
                                >
                                    Closed
                                </li>
                            </SelectArea>
                        )}
                    </FieldContainer> */}
                    <FieldContainer>
                        <Label>Status</Label>
                        :
                        {!isEdit ? (
                            <TextField>{projectData.status == true
                                ? "Active"
                                : "Closed"}</TextField>
                        ) : (
                            <InputValue
                                type="text"
                                value={
                                    projectData.status == true
                                        ? "Active"
                                        : "Inactive"
                                }
                                disabled={!isEdit}
                                className={isEdit && "active"}
                                onClick={() => setStatusOverlay(!statusOverlay)}
                            />
                        )}
                        {statusOverlay && (
                            <SelectArea ref={statusOverlayRef}>
                                <li
                                    value={true}
                                    name="status"
                                    onClick={() => {
                                        setProjectData((prev) => ({
                                            ...prev,
                                            status: true,
                                        }));
                                        setStatusOverlay(false);
                                    }}
                                >
                                    Active
                                </li>
                                <li
                                    value={false}
                                    name="status"
                                    onClick={() => {
                                        setProjectData((prev) => ({
                                            ...prev,
                                            status: false,
                                        }));
                                        setStatusOverlay(false);
                                    }}
                                >
                                    Inactive
                                </li>
                            </SelectArea>
                        )}
                    </FieldContainer>
                </ProjectDetailContainer>
            )}
        </MainContainer>
    );
}

export default ProjectDetails;

const MainContainer = styled.div`
    min-height: 300px;
`;
const ProjectDetailContainer = styled.div`
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
`;
const Heading = styled.h3`
    font-size: 24px;
    font-weight: bold;
    margin: 0 10px 10px;
    border-bottom: 1px solid #ccc;
    width: fit-content;
`;
const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 50%;
    position: relative;
    &.heading {
        justify-content: space-between;
        width: 100%;
        padding: 0;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
`;
const FieldOuterContainer = styled.div`
    display: flex;
    /* gap: 10px; */
    justify-content: space-between;
    @media all and (max-width: 1080px) {
        flex-direction: column;
    }
`;
const Label = styled.div`
    font-size: 18px;
    width: 40%;
    @media all and (max-width: 1080px) {
        width: 30%;
    }
`;
const InputValue = styled.input`
    font-size: 18px;
    padding: 5px;
    width: 40%;
    &.active {
        border: 1px solid #ccc;
        border-radius: 2px;
    }
    @media all and (max-width: 1080px) {
        width: 60%;
    }
`;
const TextArea = styled.textarea`
    font-size: 18px;
    padding: 4px;
    width: 40%;
    &.active {
        border: 1px solid #ccc;
        border-radius: 2px;
    }
`;
const TextField = styled.div`
    padding: 4px;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.border {
        border: 2px solid #0880e0;
    }
`;
const PickerDiv = styled.div`
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalendericon-new.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 18px;
    cursor: pointer;
    outline: none;
    border: none;
    height: 21px;
    display: none;
    input {
        width: 100%;
        cursor: pointer;
        border: none;
        outline: none;
        caret-color: transparent;
    }
    &.active {
        display: inline-block;
    }
`;
const SelectArea = styled.ul`
    position: absolute;
    top: 50px;
    right: 18%;
    z-index: 1;
    resize: none;
    width: 38%;
    padding: 10px 0;
    outline: unset;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fafafa;
    color: #707070;
    font-size: 16px;
    max-height: 500px;
    overflow-y: scroll;
    li {
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
            background-color: #e3e3e3;
        }
    }
    @media all and (max-width: 480px) {
        padding: 0 15px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`;
const AddNew = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    /* background-color: #0880e0; */
    background-color: ${({ type }) =>
        type === "delete" ? "#f23b3b" : "#0880e0"};
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        margin-right: 5px;
        font-size: 15px;
        font-family: "outfit_regular";
        color: #fff;
        &.filter {
            color: #fff;
        }
        @media all and (max-width: 480px) {
            margin-right: 8px;
        }
    }
    &.filter {
        background-color: #0880e0;
    }
`;
const Error = styled.p`
    color: red;
    font-size: 12px;
    height: 20px;
`;
