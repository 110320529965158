import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { supportsConfig } from "../../../AxiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../general/helpers/ButtonLoading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDDMMYYIST, getYYMMDDHHMM } from "../general/helpers/function";
import { debounce } from "lodash";

export default function NewProject({
    isBox,
    setBox,
    handleCategoryModal,
    refresh,
    setRefresh,
    isAssets,
    projectId,
}) {
    const [isClick, setClick] = useState(false);
    const [data, setData] = useState({
        project_status: "10",
        name: "",
        description: "",
        project_category: "",
        project_category_name: "",
        project_id: projectId || "",
        project: "",
    });
    const [startDate, setStartDate] = useState("");
    const [dateError, setdateError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [projects, setProjects] = useState();
    const [projectCategories, setProjectCategories] = useState();
    const [submit, setSubmit] = useState(false);
    const [error, setError] = useState("");
    const [files, setFiles] = useState([]);
    const {
        state: { user_data },
    } = useContext(Context);
    const access_token = user_data.access_token;
    const date = new window.Date();
    const datePickerRef = useRef(null);
    const overlayRef = useRef(null);
    const fileInput = useRef();

    const uploadFileHandler = (event) => {
        console.log(event, "event", files);
        setFiles(event.target.files[0]);
    };
    const deleteFile = () => {
        setFiles();
    };
    const outsideClick = (e) => {
        if (overlayRef.current && !overlayRef.current.contains(e.target)) {
            setOverlay(false);
        }
    };

    // ---- Date Calender Focus ----
    const handleFieldClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };

    //--- Clear Values---------
    const handleClear = () => {
        setError("");
        setData({
            name: "",
            description: "",
            project_category: "",
            project_category_name: "",
            project_status: "10",
        });
        setStartDate("");
        setSubmit(false);
        setFiles();
        setClick(false)
    };

    // ----- Change data -------
    const handleChange = (e) => {
        e.preventDefault();
        setError("");
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setOverlay(false);
    };
    const handleRadioChange = (e) => {
        const { value } = e.target;
        setData((prev) => ({
            ...prev,
            project_status: value,
        }));
    };

    //------------Create new category---------------------
    const handleSubmit = () => {
        if (isAssets) {
            if (data.name && projects.id && files.name && !error) {
                setLoading(true);
                const formData = new FormData();
                formData.append("name", data?.name);
                formData.append(`file`, files);

                let url = `api/v1/tenders/admin/projects/${projects.id}/project-assets/`;
                supportsConfig
                    .post(url, formData, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })
                    .then((response) => {
                        const { StatusCode, data } = response.data;
                        if (StatusCode === 6000) {
                            setBox(false);
                            setRefresh(!refresh);
                            setLoading(false);
                            handleClear();
                        } else {
                            setError(data.message);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                setSubmit(true);
            }
        } else {
            if (
                data.name &&
                data.description &&
                startDate &&
                data.project_category &&
                !error
            ) {
                setLoading(true);
                let url = "api/v1/tenders/admin/projects/";
                const formData = {
                    name: data?.name,
                    description: data?.description,
                    last_date_of_submission: getYYMMDDHHMM(startDate),
                    project_category: data?.project_category,
                    project_status: data?.project_status,
                    status: isClick,
                };
                supportsConfig
                    .post(url, formData, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })
                    .then((response) => {
                        const { StatusCode, data } = response.data;
                        if (StatusCode === 6000) {
                            setBox(false);
                            setRefresh(!refresh);
                            setLoading(false);
                            handleClear();
                        } else {
                            setError(data.message);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                setSubmit(true);
            }
        }
    };

    const handleProjectCategories = useCallback((searchTerm) => {
        supportsConfig
            .get("api/v1/tenders/admin/project-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: searchTerm ? { q: searchTerm } : {},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setProjectCategories(data.data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjectCategories();
    }, [handleProjectCategories]);

    const handleProjects = () => {
        if(projectId){
            supportsConfig
                .get(`api/v1/tenders/admin/projects/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    console.log(data, "data from project single");
                    if (StatusCode === 6000) {
                        setProjects(data.data);
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    //------------------------get Category list api--------------------------------------
    useEffect(() => {
        handleProjects();
    }, []);

    useEffect(() => {
        document.addEventListener("click", outsideClick, true);
        return () => {
            document.removeEventListener("click", outsideClick, true);
        };
    }, []);
    //-----------------------------------------------------------------------------
    return (
        <>
            <MainContainer>
                {isBox ? (
                    <Overlay onClick={() => handleCategoryModal()}></Overlay>
                ) : (
                    ""
                )}
                <BackContainer style={{ transform: isBox && "scale(1,1)" }}>
                    <Modal className="hide-scrollbar">
                        <Container>
                            <TopContainer>
                                <CoverText>
                                    New{" "}
                                    {isAssets ? "Project Assets" : "Project"}
                                </CoverText>
                                <ImageContainer
                                    onClick={() => {
                                        handleCategoryModal();
                                        handleClear();
                                    }}
                                >
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images/close.svg"
                                        alt="Cross"
                                    />
                                </ImageContainer>
                            </TopContainer>
                            <Cover>
                                <Label>Name</Label>
                                <TextArea
                                    value={data?.name}
                                    placeholder="Input name"
                                    name="name"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                {submit && data.name && error ? (
                                    <Error>{error}</Error>
                                ) : (
                                    submit &&
                                    !data.name && (
                                        <Error>This field is required</Error>
                                    )
                                )}
                            </Cover>
                            {!isAssets && (
                                <>
                                    <Cover>
                                        <Label>Description</Label>
                                        <DescriptionArea
                                            value={data?.description}
                                            placeholder="Input description"
                                            name="description"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                        {submit && data.description && error ? (
                                            <Error>{error}</Error>
                                        ) : (
                                            submit &&
                                            !data.description && (
                                                <Error>
                                                    This field is required
                                                </Error>
                                            )
                                        )}
                                    </Cover>
                                    <Cover>
                                        <Field>
                                            <Label>
                                                Last Date of Submission
                                            </Label>
                                            <TextField
                                                // className={field === "from_Date" ? "border" : ""}
                                                onClick={() =>
                                                    handleFieldClick()
                                                }
                                            >
                                                <Date
                                                    className={
                                                        startDate ? "color" : ""
                                                    }
                                                >
                                                    {startDate
                                                        ? getDDMMYYIST(
                                                              startDate
                                                          )
                                                              .split("-")
                                                              .join("/")
                                                        : "DD/MM/YYY"}
                                                </Date>

                                                <PickerDiv>
                                                    <DatePicker
                                                        ref={datePickerRef}
                                                        value=""
                                                        selected={startDate}
                                                        // maxDate={date}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        onChange={(date) => {
                                                            console.log(
                                                                date,
                                                                "date from date picker"
                                                            );
                                                            setStartDate(date);
                                                        }}
                                                    />
                                                </PickerDiv>
                                            </TextField>
                                            {submit && startDate && error ? (
                                                <Error>{error}</Error>
                                            ) : (
                                                submit &&
                                                !startDate && (
                                                    <Error>
                                                        This field is required
                                                    </Error>
                                                )
                                            )}
                                        </Field>
                                    </Cover>
                                    <Cover>
                                        <Label>Project Category</Label>
                                        <TextArea
                                            value={data?.project_category_name}
                                            name="project_category_name"
                                            placeholder="Input project category"
                                            onClick={() => setOverlay(!overlay)}
                                            onChange={(e) => {
                                                handleProjectCategories(
                                                    e.target.value
                                                );
                                                handleChange(e);
                                                setOverlay(true);
                                            }}
                                        />
                                        {overlay && (
                                            <SelectArea ref={overlayRef}>
                                                {
                                                    projectCategories.length > 0 ?
                                                        projectCategories?.map(
                                                            (category) => (
                                                                <li
                                                                    value={category.id}
                                                                    name="project_category"
                                                                    onClick={() => {
                                                                        setData(
                                                                            (prev) => ({
                                                                                ...prev,
                                                                                project_category:
                                                                                    category.id,
                                                                                project_category_name:
                                                                                    category.title,
                                                                            })
                                                                        );
                                                                        setOverlay(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {category.title}
                                                                </li>
                                                            )
                                                        )
                                                        :
                                                        <li>No Data</li>
                                                }
                                            </SelectArea>
                                        )}
                                        {submit &&
                                        data.project_category &&
                                        error ? (
                                            <Error>{error}</Error>
                                        ) : (
                                            submit &&
                                            !data.project_category && (
                                                <Error>
                                                    This field is required
                                                </Error>
                                            )
                                        )}
                                    </Cover>
                                    <Cover>
                                        <Label>Project Status</Label>
                                        <RadioContainer>
                                            <RadioSpan className="flex items-center gap-3 max-[480px]:gap-[4px]">
                                                <input
                                                    type="radio"
                                                    id="active"
                                                    name="project_status"
                                                    value="10"
                                                    checked={
                                                        data?.project_status ===
                                                        "10"
                                                    }
                                                    onChange={() => {
                                                        setData((prev) => ({
                                                            ...prev,
                                                            project_status: "10",
                                                        }));
                                                    }}
                                                    // className={`w-4 h-4 ${styles.radioButton} ${
                                                    //     orderType === "10" ? styles.radioButtonBlue : ""
                                                    // }`}
                                                />
                                                <label
                                                    htmlFor="active"
                                                    className="text-gray-500 font-medium text-sm min-w-[79px] max-[480px]:min-w-[30px]"
                                                >
                                                    Active
                                                </label>
                                            </RadioSpan>
                                            <RadioSpan className="flex items-center gap-3 max-[480px]:gap-[4px]">
                                                <input
                                                    type="radio"
                                                    id="closed"
                                                    name="project_status"
                                                    value="20"
                                                    checked={
                                                        data?.project_status ===
                                                        "20"
                                                    }
                                                    onChange={() => {
                                                        setData((prev) => ({
                                                            ...prev,
                                                            project_status: "20",
                                                        }));
                                                    }}
                                                    // className={`w-4 h-4 ${styles.radioButton} ${
                                                    //     orderType === "20" ? styles.radioButtonBlue : ""
                                                    // }`}
                                                />
                                                <label
                                                    htmlFor="closed"
                                                    className="text-gray-500 font-medium text-sm min-w-[79px] max-[480px]:min-w-[79px]"
                                                >
                                                    Closed
                                                </label>
                                            </RadioSpan>
                                        </RadioContainer>
                                    </Cover>
                                    <Cover>
                                        <Label>Status</Label>
                                        <Switch>
                                            <Button
                                                className={
                                                    isClick ? "active" : ""
                                                }
                                                onClick={() =>
                                                    setClick(!isClick)
                                                }
                                            >
                                                <div
                                                    className={
                                                        isClick ? "active" : ""
                                                    }
                                                ></div>
                                            </Button>
                                            <Text>
                                                {isClick
                                                    ? "Active"
                                                    : "Inactive"}
                                            </Text>
                                        </Switch>
                                    </Cover>
                                </>
                            )}
                            {isAssets && (
                                <>
                                    <Cover>
                                        <Label>Project</Label>
                                        <InputWrapper>
                                            <TextArea
                                                value={projects?.name} // Use your state or props here
                                                name="project"
                                                placeholder="Input project"
                                                disabled // Keep the input disabled at all times
                                            />
                                            <LockIcon>🔒</LockIcon>
                                        </InputWrapper>
                                        {/* {overlay && (
                                            <SelectArea ref={overlayRef}>
                                                {projects?.map((project) => (
                                                    <li
                                                        value={project.id}
                                                        name="project"
                                                        onClick={() => {
                                                            setData((prev) => ({
                                                                ...prev,
                                                                project:
                                                                    project.name,
                                                                project_id:
                                                                    project.id,
                                                            }));
                                                            setOverlay(false);
                                                        }}
                                                    >
                                                        {project.name}
                                                    </li>
                                                ))}
                                            </SelectArea>
                                        )} */}
                                        {submit && projects?.name && error ? (
                                            <Error>{error}</Error>
                                        ) : (
                                            submit &&
                                            !projects?.name && (
                                                <Error>
                                                    This field is required
                                                </Error>
                                            )
                                        )}
                                    </Cover>
                                    <Cover>
                                        <Label>Attachment</Label>
                                        <Attachment>
                                            <CoverScroll>
                                                <Items>
                                                    {files?.name && (
                                                        <Item
                                                            Item
                                                            key={files.name}
                                                            id="file"
                                                        >
                                                            <ImgContainer>
                                                                <img
                                                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/fileupload.svg"
                                                                    alt="Attachments"
                                                                />
                                                            </ImgContainer>
                                                            <TextName>
                                                                {files.name}
                                                            </TextName>
                                                            <ImgContainer
                                                                onClick={() => {
                                                                    deleteFile();
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/closeicon.svg"
                                                                    alt="Close"
                                                                />
                                                            </ImgContainer>
                                                        </Item>
                                                    )}
                                                </Items>
                                            </CoverScroll>
                                            <Input
                                                type="file"
                                                name="file_upload"
                                                ref={fileInput}
                                                // multiple
                                                onChange={uploadFileHandler}
                                            />
                                            <Icon
                                                onClick={() => {
                                                    fileInput.current.click();
                                                }}
                                            >
                                                <img
                                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/attachment.svg"
                                                    alt="Attach icon"
                                                />
                                            </Icon>
                                        </Attachment>
                                        {submit &&
                                        files &&
                                        error ? (
                                            <Error>{error}</Error>
                                        ) : (
                                            submit &&
                                            !files && (
                                                <Error>
                                                    This field is required
                                                </Error>
                                            )
                                        )}
                                    </Cover>
                                </>
                            )}
                            <CoverSubmit>
                                <Cancel
                                    onClick={() => {
                                        handleClear();
                                        handleCategoryModal();
                                    }}
                                >
                                    Cancel
                                </Cancel>
                                <Submit onClick={() => handleSubmit()}>
                                    {isLoading ? <ButtonLoader /> : "Save"}
                                </Submit>
                            </CoverSubmit>
                        </Container>
                    </Modal>
                </BackContainer>
            </MainContainer>
        </>
    );
}
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    background: #fff;
    max-height: 100vh;
    overflow: scroll;
`;
const MainContainer = styled.div``;
const CoverText = styled.h3`
    color: #1a6eb0;
    margin-bottom: 5px;
`;
const Container = styled.div`
    padding: 30px;
    @media all and (max-width: 480px) {
        padding: 30px 20px;
    }
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 25px;
    margin-bottom: 25px;
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    & img {
        display: block;
        width: 100%;
    }
`;
const Cover = styled.div`
    margin-bottom: 20px;
    position: relative;
`;

const Attachment = styled.div`
    width: 100%;
    padding: 0 10px;
    height: 60px;
    /* border: 1px solid #bebebe; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    background-color: #f5f7fb;
    /* background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/attachment.svg"); */
    background-repeat: no-repeat;
    right: 10px;
    /* cursor: pointer; */
    background-position: right 18px top 13px;
    /* &:hover {
        border: 1px solid #0883e0;
    } */
    @media all and (max-width: 640px) {
        height: 45px;
    }
`;
const CoverScroll = styled.div`
    /* overflow-x: scroll; */
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Items = styled.div`
    min-width: 700px;
    display: flex;
`;
const Item = styled.div`
    display: flex;
    overflow: hidden;
    margin-right: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    width: 24%;
    background: #ffffff;
    border: 1px solid #0884df;
    border-radius: 2px;
`;
const TextName = styled.div`
    color: #707070;
    font-family: "outfit_light";
    font-size: 14px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const ImgContainer = styled.div`
    margin-right: 5px;
    width: 20px;
    :last-child {
        margin-left: 5px;
        margin-right: 0;
        cursor: pointer;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const Input = styled.input`
    position: absolute;
    right: 20px;
    width: 32px;
    height: 0;
    opacity: 0;
    cursor: pointer;
    outline-color: #0884df !important;
    /* @media all and (max-width: 1280px) {
        right: 21px;
        width: 28px;
        height: 28px;
    }
    @media all and (max-width: 980px) {
        width: 37px;
        height: 37px;
    }
    @media all and (max-width: 768px) {
        width: 25px;
        height: 25px;
    }
    @media all and (max-width: 360px) {
        width: 22px;
        height: 22px;
    } */
`;
const Icon = styled.div`
    width: 70px;
    cursor: pointer;
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
    & img {
        width: 100%;
        display: block;
        cursor: pointer;
    }
    @media all and (max-width: 1280px) {
        width: 50px;
    }
    @media all and (max-width: 640px) {
        width: 65px;
    }
    @media all and (max-width: 480px) {
        width: 80px;
    }
`;
const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 15px;
    /* width: 250px; */
    background-color: #f5f7fb;
    border: 1px solid #e6edfb;
    border-radius: 1px;
`;
const RadioSpan = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const Field = styled.div`
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
`;
const TextField = styled.div`
    padding: 15px;
    /* width: 250px; */
    background-color: #f5f7fb;
    border: 1px solid #e6edfb;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.border {
        border: 2px solid #0880e0;
    }
`;
const Date = styled.span`
    display: inline-block;
    color: #9c9c9c;
    font-family: "outfit_regular";
    text-transform: capitalize;
    font-size: 14px;
    cursor: pointer;
    &.color {
        color: #0a0a0a;
    }
`;
const Label = styled.div`
    color: #929292;
    margin-bottom: 10px;
    font-family: "outfit_light";
    font-size: 16px;
`;
const PickerDiv = styled.div`
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fcalendericon-new.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 18px;
    cursor: pointer;
    outline: none;
    border: none;
    height: 21px;

    input {
        width: 100%;
        cursor: pointer;
        border: none;
        outline: none;
        caret-color: transparent;
    }
`;
const Text = styled.div`
    color: #707070;
    font-family: "outfit_regular";
`;
const SelectArea = styled.ul`
    position: absolute;
    bottom: 60px;
    left: 0;
    z-index: 1;
    resize: none;
    width: 100%;
    padding: 10px 0;
    outline: unset;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fafafa;
    color: #707070;
    font-size: 16px;
    max-height: 500px;
    overflow-y: scroll;
    li {
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
            background-color: #e3e3e3;
        }
    }
    @media all and (max-width: 480px) {
        padding: 0 15px;
    }
`;
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const TextArea = styled.input`
    resize: none;
    width: 100%;
    padding: 17px 15px;
    padding-right: 40px; // Add padding to the right to make space for the lock icon
    outline: unset;
    background-color: #f5f7fb;
    color: #707070;
    font-size: 16px;
    ::placeholder {
        color: #aaaaaa;
    }
    @media all and (max-width: 480px) {
        padding: 15px 15px;
        padding-right: 40px; // Add padding to the right to make space for the lock icon
    }
`;

const LockIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const DescriptionArea = styled.textarea`
    resize: none;
    width: 100%;
    padding: 17px 15px;
    outline: unset;
    background-color: #f5f7fb;
    color: #707070;
    font-size: 16px;
    ::placeholder {
        color: #aaaaaa;
    }
    @media all and (max-width: 480px) {
        padding: 15px 15px;
    }
`;
const Switch = styled.div`
    margin: 15px 0;
    display: flex;
    align-items: center;
`;
const Button = styled.div`
    border: 1px solid #abc1ed;
    background: #f5f7fb;
    height: 23px;
    width: 43px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    transition: 1s ease;
    padding: 4px;
    position: relative;
    &.active {
        border: 1px solid #0880e0;
    }
    & div {
        width: 15px;
        height: 14px;
        background: #abc1ed;
        position: absolute;
        transition: 1s ease;
        border-radius: 50%;
        &.active {
            transition: 1s ease;
            right: 3px;
            transition-duration: 0.4s;
            background: #0880e0;
            border: 1px solid #0880e0;
        }
    }
`;
const CoverSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
    @media all and (max-width: 360px) {
        flex-direction: column;
        align-items: center;
    }
`;
const Cancel = styled.div`
    background: #fff;
    padding: 10px 38px;
    width: 100%;
    height: 45px;
    color: #0880e0;
    border: 1px solid #0880e0;
    margin-right: 15px;
    cursor: pointer;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 130px;
        height: 40px;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 10px;
        width: 100%;
        height: 45px;
        margin-right: 0px;
    }
`;
const Submit = styled.div`
    background-color: #0880e0;
    color: #fff;
    width: 150px;
    height: 45px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 130px;
        height: 40px;
    }
    @media all and (max-width: 360px) {
        width: 100%;
        height: 45px;
    }
`;
const Error = styled.p`
    color: red;
    font-size: 12px;
    height: 20px;
`;
